import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import '../css/pages/studio.css'
import '../css/aos.css'

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

const StudioPage = ({ pathContext: { locale } }) => (
	<>
		<Helmet
			bodyAttributes={{
				class: 'transition-support webkit studio'
      }}
      title="Studio | Studio Legale Cirio - Francescon - Stella - Sartori - Maccari"
		>
		</Helmet>
		<Layout locale={locale}>
			<div className="studio">
				<div className="content clearfix">
					<div className="section-block intro-title-2 header-image redFilter"></div>
					<div className="underHeader">
						<div className="item">
							<h1 data-aos = "fade-up"><FormattedHTMLMessage id="studioTitle" /></h1>
							<p data-aos = "fade-up" data-aos-duration = "1000"><FormattedHTMLMessage id="studioSubtitle" /></p>
						</div>
					</div>
					<div className="section-block intro-title-2 allGray" id="img1"></div>
					<div className="firstText invert">
						<div data-aos = "fade-up" className="item">
							<p className="white"><FormattedHTMLMessage id="studioFirstText" /></p>
						</div>
					</div>
					<div className="section-block intro-title-2 allGray" id="img2"></div>
					<div data-aos = "fade-up" className="secondText">
						<div className="item">
							<p><FormattedHTMLMessage id="studioSecondText" /></p>
						</div>
					</div>
					<div className="section-block intro-title-2 allGray" id="img3"></div>
				</div>
			</div>
		</Layout >
	</>
)

export default StudioPage
